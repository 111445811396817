import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { sendMessage } from '../actions';
import { Link } from 'react-router-dom';
import ScrollToTop from '../utils/ScrollToTop';
import Image from '../components/elements/Image';

import imageGen from '../assets/emoji/image-gen.svg';
import chat from '../assets/emoji/chat.svg';
import nsfw from '../assets/emoji/nsfw.svg';

import Subscribe from '../components/Subscribe';
import { infographics } from 'assets/static/infographics';
import { useSelector } from 'react-redux';

const Home = () => {
  const { t } = useTranslation();
  const [modalContent, setModalContent] = React.useState({
    type: 'block',
    title: t('message_sent_error'),
    body: t('message_sent_error_description'),
  });
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [actionModalIsOpen, setActionModalIsOpen] = React.useState(false);
  let language = useSelector((state) => state.navigationReducer.language);

  function openModal() {
    setIsOpen(true);
  }

  function sendActionMessage(email, fullName, phoneNumber, message) {
    sendMessage(fullName, email, phoneNumber, message)
      .then((res) => {
        setModalContent({
          type: 'congrats',
          title: t('message_sent_successfully'),
          body: t('message_sent_successfully_description'),
        });
        closeModal();
        setActionModalIsOpen(true);
      })
      .catch((e) => {
        setModalContent({
          type: 'block',
          title: t('message_sent_error'),
          body: t('message_sent_error_description'),
        });
        closeModal();
        setActionModalIsOpen(true);
      });
  }

  function closeModal() {
    setIsOpen(false);
  }

  function closeActionModal() {
    setActionModalIsOpen(false);
  }

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  const features = [
    {
      index: 1,
      name: 'features_1',
      image: imageGen,
    },
    {
      index: 2,
      name: 'features_2',
      image: nsfw,
    },
    {
      index: 3,
      name: 'features_3',
      image: chat,
    },
  ];

  return (
    <section className="body-wrap">
      <ScrollToTop />
      <div
        style={{
          zIndex: '1',
        }}
      ></div>
      <div
        style={{
          position: 'absolute',
          zIndex: -1,
        }}
      ></div>
      <div className="container">
        <div
          className="hero-inner"
          style={{ height: window.innerHeight - 150 }}
        >
          <div
            className="hero-copy info-box"
            style={{
              marginTop: 50,
              display: 'flex',
              alignItems: 'flex-start',
            }}
          >
            <div style={{ columnGap: 100 }} className={'hero-holder'}>
              <div className="hero-left">
                <p
                  className="home-title"
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <span className="home-title-light">{t('home_title')}</span>
                  <span className="home-title-dark">{t('home_subtitle')}</span>
                </p>

                {/* <div className="home-illustration">
              <Image src={illustration} width={400} height={400} />
            </div> */}

                <div className="mt-5 w-100"></div>
                <div className="hero-cta">
                  <p
                    className="button-white"
                    href="/#"
                    onClick={() => {
                      window.location.href = 'mailto:contact@brkd.org';
                      e.preventDefault();
                    }}
                    style={{ width: '200px' }}
                  >
                    {t('contact')}
                  </p>
                  <Link
                    to="/about"
                    className="button-secondary"
                    style={{ width: '200px' }}
                  >
                    {t('learn_more')}
                  </Link>
                </div>
              </div>
              <div className="hero-right">
                {/* <LogoFullVertical width={150} height={150} /> */}
              </div>
            </div>
            <div className="infographics-holder-home w-100 m-auto mb-0">
              {infographics.map((element, index) => (
                <div className="infographics-item-holder" key={index}>
                  <p className="infographics-number">{element.number}</p>
                  <p className="infographics-text">{t(element.text)}</p>
                </div>
              ))}
            </div>
          </div>
        </div>

        <br />
        <br />
        <br />

        <p className="path-description typography-heading-large text-center bold mb-2">
          {t('home_header')}
        </p>

        <div className="hero-secondary">
          <p className="hero-secondary-body">{t('welcome_description')}</p>
        </div>

        <div
          className={'container services-holder'}
          style={{ marginTop: '50px', zIndex: 10 }}
        >
          {features.map((element, index) => {
            return (
              <p className={'service-card'} key={index}>
                <div className="service-image-background">
                  <Image
                    className={'service-image'}
                    src={element.image}
                    width={90}
                    height={90}
                  />
                </div>
                <p className="service-title">{t(element.name)}</p>
                <p className="service-description">
                  {t(element.name + '_description')}
                </p>
              </p>
            );
          })}
        </div>
        <br />
        <br />

        {/* <div className={"store-buttons"}>
          <Link to="/play">
            <Image src={google} width={200} height={600} />
          </Link>
          <Link to="/store">
            <Image src={apple} width={165} height={600} />
          </Link>
        </div> */}

        <div>
          <div className="hero-secondary">
            <Subscribe />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Home;
