import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { footerNav } from "../../../assets/static/footerNav";

const FooterNav = ({ className, ...props }) => {
  const { t } = useTranslation();

  const classes = classNames("footer-nav", className);

  return (
    <nav {...props} className={classes}>
      <ul className="list-reset" style={{ columnGap: "50px" }}>
        {footerNav.map((route, index) => (
          <li key={index}>
            <div className="footer-nav-title">
              <span
                className="footer-title"
                style={{ fontWeight: "700" }}
                key={index}
              >
                {t(route.name)}
              </span>
              <br />
              {route.subsections.map((subroute, index) =>
                subroute.away ? (
                  <Link
                    className="footer-subtitle"
                    onClick={() => {
                      window.location.href = subroute.path;
                    }}
                  >
                    {t(subroute.name)}
                  </Link>
                ) : (
                  <Link className="footer-subtitle" to={subroute.path}>
                    {t(subroute.name)}
                  </Link>
                )
              )}
            </div>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default FooterNav;
