import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Logo from "./partials/LogoFull";
import FooterNav from "./partials/FooterNav";
import { useTranslation } from "react-i18next";
const propTypes = {
  topOuterDivider: PropTypes.bool,
  topDivider: PropTypes.bool,
};

const defaultProps = {
  topOuterDivider: false,
  topDivider: false,
};

const Footer = ({ className, topOuterDivider, topDivider, ...props }) => {
  const classes = classNames(
    "site-footer center-content-mobile",
    topOuterDivider && "has-top-divider",
    className
  );

  const { t } = useTranslation();

  return (
    <footer {...props} className={classes} id={"foot"}>
      <div className="">
        <div
          className={classNames(
            "site-footer-inner",
            topDivider && "has-top-divider"
          )}
        >
          <div className="footer-top space-between text-xxs footer-align">
            <div style={{ flex: 1 }}>
              <Logo />
            </div>

            <div
              className="footer-top space-between-get-in-touch text-xxs"
              style={{ flex: 1 }}
            >
              <div
                className="footer-bottom space-between text-xxs invert-order-desktop"
                style={{
                  flex: 2,
                  display: "flex",
                  flexDirection: "row",
                  columnGap: "50px",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <FooterNav />
              </div>
            </div>
          </div>
          <div className="divider"></div>
          <div className="footer-copyright footer-top space-between text-xxxs invert-order-desktop">
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "row",
                columnGap: "50px",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
              className="footer-social-holder"
            ></div>
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "row",
                columnGap: "50px",
                alignItems: "center",
              }}
            >
              <p className="footer-copyright" style={{ marginBottom: "0" }}>
                {t("all_rights_reserved")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;
