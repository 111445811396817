export const footerNav = [
  {
    title: 'Index',
    index: 1,
    name: 'index',
    subsections: [
      {
        title: 'Products',
        index: 1,
        path: '/products',
        name: 'products',
        component: <></>,
      },
      {
        title: 'About us',
        index: 1,
        path: '/about',
        name: 'about-us',
        component: <></>,
      },
      {
        title: 'Contact us',
        index: 1,
        path: '/about',
        name: 'contact-us',
        component: <></>,
      },
      {
        title: 'Telegram',
        index: 1,
        path: 'https://t.me/brkd_labs',
        away: true,
        name: 'Telegram',
        component: <></>,
      },
    ],
  },
];
