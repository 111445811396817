import React from "react";
// Layouts
import LayoutDefault from "./layouts/LayoutDefault";
import AppRoute from "./utils/AppRoute";
import { Switch } from "react-router-dom";
import Home from "./layouts/Home";
import NotFound from "./layouts/NotFound";
import AboutUs from "./layouts/AboutUs";
import { routes } from "./assets/static/router";

function Routes() {
  return (
    <Switch>
      <AppRoute exact path="/" component={Home} layout={LayoutDefault} />

      <AppRoute
        exact
        path="/about"
        component={AboutUs}
        layout={LayoutDefault}
      />

      {routes.map((route) => {
        return (
          <AppRoute
            exact
            path={route.path}
            component={route.component}
            layout={LayoutDefault}
          />
        );
      })}

      <AppRoute exact path="*" component={NotFound} layout={LayoutDefault} />
    </Switch>
  );
}

export default Routes;
