import React from "react";
import ContactUs from "../components/layout/ContactUs";
import NavigationHeader from "../components/layout/partials/NavigationHeader";
import { useTranslation } from "react-i18next";
import ScrollToTop from "../utils/ScrollToTop";
import PortfolioLogos from "../components/layout/PortfolioLogos";

const Contacts = () => {
  const { t } = useTranslation();

  return (
    <section className="body-wrap">
      <ScrollToTop />

      <div className="container">
        <div className="hero-inner">
          <div className="hero-copy" style={{ flex: 3 }}>
            <NavigationHeader
              location={[t("home"), t("contacts")]}
              title={t("contacts")}
              description={t("contacts_description")}
            />
          </div>
        </div>

        <div>
          <div className="contact-holder">
            <ContactUs />
          </div>
          <div className="hero-secondary"></div>
        </div>
      </div>
    </section>
  );
};

export default Contacts;
