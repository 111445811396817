import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Logo from "./partials/Logo";
import HeaderNav from "./partials/HeaderNav";
import { routes } from "../../assets/static/router";
import { setLanguage, setMenu } from "../../actions";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Select from "react-select";

const propTypes = {
  navPosition: PropTypes.string,
  hideNav: PropTypes.bool,
  hideSignin: PropTypes.bool,
  bottomOuterDivider: PropTypes.bool,
  bottomDivider: PropTypes.bool,
};

const defaultProps = {
  navPosition: "",
  hideNav: false,
  hideSignin: false,
  bottomOuterDivider: false,
  bottomDivider: false,
};

const Header = ({
  className,
  navPosition,
  hideNav,
  hideSignin,
  bottomOuterDivider,
  bottomDivider,
  props,
}) => {
  const [isActive, setIsactive] = useState(false);
  const dispatch = useDispatch();
  const nav = useRef(null);
  const hamburger = useRef(null);
  const { t, i18n } = useTranslation();
  const location = useLocation();
  let isLogged = useSelector((state) => state.navigationReducer.bearerToken);
  let lang = useSelector((state) => state.navigationReducer.language);
  let history = useHistory();

  // console.log(isLogged.navigationReducer.bearerToken);

  let styles = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      border: 0,
      backgroundColor: "transparent",
      fontSize: 14,
      height: 28,
      paddingLeft: 5,
      paddingRight: 5,
      fontWeight: "600",
      boxShadow: "none",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        fontSize: 14,
        marginTop: 0,
        display: "flex",
        justifyContent: "center",
      };
    },
    singleValue: (provided) => ({
      ...provided,
      color: "#fff",
    }),
  };

  useEffect(() => {
    isActive && openMenu();
    document.addEventListener("keydown", keyPress);
    document.addEventListener("click", clickOutside);

    let menuIndex = routes.filter(
      (e) => e.name === location.pathname.split("/")[1]
    )[0]?.index;
    if (menuIndex) {
      ul(menuIndex);
    }

    return () => {
      document.removeEventListener("keydown", keyPress);
      document.removeEventListener("click", clickOutside);
      closeMenu();
    };
  }, [isLogged]);

  const openMenu = () => {
    document.body.classList.add("off-nav-is-active");
    nav.current.style.maxHeight = nav.current.scrollHeight + "px";
    setIsactive(true);
  };

  const closeMenu = () => {
    document.body.classList.remove("off-nav-is-active");
    nav.current && (nav.current.style.maxHeight = null);
    setIsactive(false);
  };

  const keyPress = (e) => {
    isActive && e.keyCode === 27 && closeMenu();
  };

  const clickOutside = (e) => {
    if (!nav.current) return;
    if (
      !isActive ||
      nav.current.contains(e.target) ||
      e.target === hamburger.current
    )
      return;
    closeMenu();
  };

  const classes = classNames(
    "site-header",
    bottomOuterDivider && "has-bottom-divider",
    className
  );

  const ul = (index) => {
    closeMenu();
    let selectedMenu = routes.filter((route) => route.index === index)[0];
    setMenu(selectedMenu.index, dispatch);
    var underlines = document.querySelectorAll(".underline");

    for (var i = 0; i < underlines.length; i++) {
      underlines[i].style.transform =
        "translate3d(" +
        index * (nav.current.offsetWidth / routes.length) +
        "px,0,0)";
    }
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <header {...props} className={classes} id={"head"}>
        <div className="container">
          <div
            className={classNames(
              "site-header-inner",
              bottomDivider && "has-bottom-divider"
            )}
          >
            <Logo height={50} width={50} />
            <>
              <button
                ref={hamburger}
                className="header-nav-toggle"
                onClick={isActive ? closeMenu : openMenu}
              >
                <span className="screen-reader">{t("menu")}</span>
                <span className="hamburger">
                  <span className="hamburger-inner"></span>
                </span>
              </button>
              <nav ref={nav} className={"header-nav black"}>
                <div className="header-nav-inner">
                  <HeaderNav navPosition={navPosition} onClick={ul} />
                </div>
              </nav>
            </>
          </div>
        </div>
      </header>
    </>
  );
};

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
