import {
  MENU_CHANGE,
  ADMIN_MENU_CHANGE,
  LANGUAGE_CHANGE,
  back,
  HOST_URL,
} from "../constants";
import axios from "axios";
import { makeHeaders } from "../utils";
import store from "../store";
import navigationReducer from "../reducers/navigationReducer";
import { useTranslation } from "react-i18next";

export function setLanguage(language, dispatch, i18n, t) {
  i18n.changeLanguage(language);

  document.title = "BRKD Labs LLC.";

  return dispatch({
    type: LANGUAGE_CHANGE,
    payload: language,
  });
}

export function sendMessage(fullName, email, phoneNumber, message) {
  let link = `${back}/api/contacts`;

  var myHeaders = {
    "Content-Type": "application/json",
  };

  let data = {
    email: email,
    fullName: fullName,
    phoneNumber: phoneNumber,
    message: message,
  };

  return axios.post(link, data, { headers: myHeaders });
}

export function setMenu(menu, dispatch) {
  return dispatch({
    type: MENU_CHANGE,
    payload: menu,
  });
}
