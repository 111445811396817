import Contacts from "../../layouts/Contacts";
import Home from "../../layouts/Home";
import Features from "../../layouts/Features";

export const routes = [
  {
    title: "Home",
    index: 0,
    path: "/",
    name: "home",
    component: Home,
  },
  {
    title: "Products",
    index: 1,
    path: "/products",
    name: "products",
    component: Features,
  },

  {
    title: "About",
    index: 4,
    path: "/about",
    name: "about",
    component: Contacts,
  },
];
