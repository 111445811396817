import React, { useEffect } from "react";
import { infographics } from "../assets/static/infographics";
import NavigationHeader from "../components/layout/partials/NavigationHeader";
import { useTranslation } from "react-i18next";
import ScrollToTop from "../utils/ScrollToTop";
import Subscribe from "components/Subscribe";

const AboutUs = () => {
  let [scrollPostion, setScrollPosition] = React.useState(0);
  const { t } = useTranslation();

  const listenToScrollEvent = () => {
    document.addEventListener("scroll", () => {
      requestAnimationFrame(() => {
        // Calculates the scroll distance
        calculateScrollDistance();
      });
    });
  };

  const calculateScrollDistance = () => {
    const scrollTop = window.pageYOffset;
    const winHeight = window.innerHeight;
    const docHeight = getDocHeight();
    const totalDocScrollLength = docHeight - winHeight;
    const scrollPostion = Math.floor((scrollTop / totalDocScrollLength) * 100);

    setScrollPosition(scrollPostion);
  };

  const getDocHeight = () => {
    return Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight,
      document.body.clientHeight,
      document.documentElement.clientHeight
    );
  };

  useEffect(() => {
    listenToScrollEvent();
  }, []);

  return (
    <section className="body-wrap">
      <ScrollToTop />
      <div className="container">
        <div className="hero-inner">
          <div className="hero-copy" style={{ flex: 3 }}>
            <NavigationHeader
              location={[t("home"), t("about")]}
              title={t("about")}
              description={t("about_description")}
            />
          </div>
        </div>

        <div className="infographics-holder">
          {infographics.map((element, index) => (
            <div className="infographics-item-holder" key={index}>
              <p className="infographics-number">{element.number}</p>
              <p className="infographics-text">{t(element.text)}</p>
            </div>
          ))}
        </div>

        <div>
          <div className="hero-secondary w-100">
            <p className="hero-secondary-body">{t("about_paragraph_1_body")}</p>
          </div>
        </div>

        <div>
          <div className="hero-secondary">
            <Subscribe />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
