export const infographics = [
  {
    id: 0,
    number: '6mln+',
    text: 'infographics_1',
  },
  {
    id: 1,
    number: '200k+',
    text: 'infographics_2',
  },
  {
    id: 2,
    number: '9k+',
    text: 'infographics_3',
  },
];
