import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Image from "../components/elements/Image";
import NavigationHeader from "../components/layout/partials/NavigationHeader";
import ScrollToTop from "../utils/ScrollToTop";

import imageGen from "../assets/emoji/image-gen.svg";
import chat from "../assets/emoji/chat.svg";
import nsfw from "../assets/emoji/nsfw.svg";
import { useSelector } from "react-redux";

const Features = () => {
  const { t } = useTranslation();
  let language = useSelector((state) => state.navigationReducer.language);

  const features = [
    {
      index: 1,
      name: "features_1",
      image: imageGen,
    },
    {
      index: 2,
      name: "features_2",
      image: nsfw,
    },
    {
      index: 3,
      name: "features_3",
      image: chat,
    },
  ];

  return (
    <section className="body-wrap">
      <ScrollToTop />
      <div className="container">
        <div className="hero-inner">
          <div className="hero-copy" style={{ flex: 3 }}>
            <NavigationHeader
              location={[t("home"), t("products")]}
              title={t("products")}
              description={t("features_paragraph_1_title")}
            />
          </div>
        </div>
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: 100,
        }}
      ></div>
      <div
        className={"container services-holder"}
        style={{ marginTop: "0px", zIndex: 10 }}
      >
        {features.map((element, index) => {
          return (
            <p className={"service-card"} key={index}>
              <div className="service-image-background">
                <Image
                  className={"service-image"}
                  src={element.image}
                  width={84}
                  height={84}
                />
              </div>
              <p className="service-title">{t(element.name)}</p>

              <p
                className="service-description"
                style={{ whiteSpace: "pre-line", lineBreak: "auto" }}
              >
                {t(element.name + "_description")}
              </p>
            </p>
          );
        })}
      </div>
      <div>
        <div className="hero-secondary">
          <p className="hero-secondary-body">
            {t("features_paragraph_1_body")}
          </p>
        </div>
      </div>
    </section>
  );
};

export default Features;
